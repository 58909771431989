<template>
  <div
    ref="contentRef"
    class="product-view"
    :class="{ erp: isErp }"
  >
    <ClipLoader
      v-if="loaderIsShown"
      placeholder="Подождите, идет загрузка..."
    />
    <div
      v-else
      class="product-view__wrapper"
    >
      <div
        v-if="title || breadcrumbs || isShowSubHeader || suggestText"
        class="product-view__header"
      >
        <NuxtLink
          v-if="backButtonSlug"
          class="btn btn-secondary mm-back-button"
          :to="backButtonSlug"
        >
          <SvgIcon
            class="mm-back-button__icon"
            :src="EIconPath.NavigationArrowLeft20PxSvg"
          />
          Назад
        </NuxtLink>
        <div
          v-if="suggestText && searchText"
          class="suggest-text"
        >
          Результаты поиска: по запросу «{{ replacedText }}» ничего не найдено, показаны результаты по запросу «{{ suggestText }}»
        </div>

        <div
          v-if="breadcrumbs && !suggestText"
          class="breadcrumbs__wrap"
        >
          <Breadcrumbs :items="breadcrumbs" />
        </div>

        <div
          v-if="title || searchText"
          class="headline"
        >
          <h1>
            {{ title || searchText }}
          </h1>
          <span
            v-if="!hideProductsCount && productsData?.countGte"
            class="headline__products-count"
            style="margin-right: 5px"
          >
            более
          </span>
          <span
            v-if="!hideProductsCount"
            class="headline__products-count"
          >
            {{ t(ERuLocales.Item, productsData?.count || 0) }}
          </span>
        </div>

        <slot name="subHeader" />
      </div>

      <EmptyBanner
        v-if="!productsData?.items?.length && !pendingProductsData && !isSelectedFilters && !isErp"
        class="empty-banner"
        title-text="По вашему запросу ничего
        не найдено"
        reason-text="Убедитесь, что название бренда и товара написано правильно,
        попробуйте сократить запрос или задать его по-другому"
      >
        <NuxtLink
          :to="`${ociMode ? '/oci' : ''}/categories/`"
          class="btn btn-text"
        >
          Перейти в каталог
          <SvgIcon src="navigation/main-arrow" />
        </NuxtLink>
      </EmptyBanner>

      <template v-else>
        <div
          v-if="!hideControls"
          class="controls"
        >
          <div
            v-if="!hideFilters"
            class="filters"
          >
            <div class="filters__wrap">
              <div
                key="sort-button"
                class="filters__item"
              >
                <DropdownSort
                  v-if="productsData?.items?.length"
                  :model-value="selectedSort"
                  :filter="sortOptions"
                  :disabled="productsLoaderIsShown"
                  class="controls-sort"
                  is-close-after-select
                  filter-offset-x="50"
                  @update:model-value="onUpdateSort"
                />
              </div>

              <div
                v-for="filterItem in filtersForListingHeader"
                :key="filterItem.filter?.id"
                class="filters__item"
              >
                <LazyBottomSheetFilter
                  v-if="isMobileOrSmallMobile"
                  :model-value="filterItem.filterState"
                  :filter="filterItem.filter"
                  :oci-mode="ociMode"
                  :get-filter-active-text="(filterState, originalFilter) => getFilterActiveText(filterState, originalFilter)"
                  :disabled="productsLoaderIsShown"
                  :submit-loader="loadDataDisabler"
                  :is-erp="isErp"
                  :base-filter-props="supplierCatalogsFilter.getFilterBaseProps(filterItem.filter?.id)"
                  @update:model-value="onUpdateFilters(filterItem, $event)"
                />

                <DropdownFilter
                  v-else
                  v-model="filterItem.filterState"
                  :filter="filterItem.filter"
                  :disabled="productsLoaderIsShown"
                  :submit-loader="loadDataDisabler"
                  class="filter"
                  :base-filter-props="supplierCatalogsFilter.getFilterBaseProps(filterItem.filter?.id)"
                  :get-filter-active-text="(filterState, originalFilter) => getFilterActiveText(filterState, originalFilter)"
                  :open="filtersOpeningMap?.get(filterItem.filter?.id)"
                  @open="onChangeDisplayFilter(filterItem.filter?.id, true)"
                  @close="onChangeDisplayFilter(filterItem.filter?.id, false)"
                  @toggle="onChangeDisplayFilter(filterItem.filter?.id, !filtersOpeningMap?.get(filterItem.filter?.id))"
                  @reset="onResetFilters(filterItem.filter?.id)"
                  @submit="onSubmitFilters(filterItem.filter?.id)"
                />
              </div>

              <button
                v-if="isSelectedFilters"
                key="clear-button"
                class="btn btn-secondary"
                :disabled="loadDataDisabler.value || showMoreDisabler.value"
                @click="onClearAllFilters"
              >
                Очистить все
                <SvgIcon src="navigation/close-20px" />
              </button>
            </div>
          </div>

          <div class="buttons">
            <button
              v-if="!hideFilters"
              class="btn btn-primary"
              :disabled="loadDataDisabler.value || showMoreDisabler.value"
              @click="visibleFilters = true"
            >
              Все фильтры
              <SvgIcon src="action/range" />
            </button>
            <div
              v-else
              class="buttons--tab-title"
            >
              <p>Товары, подобранные системой по параметрам потребности</p>
            </div>
            <button
              v-if="isMounted"
              class="btn btn-square btn-square--36px btn-secondary"
              :disabled="loadDataDisabler.value || showMoreDisabler.value"
              @click="onChangeViewData"
            >
              <SvgIcon :src="srcIconButton" />
            </button>
          </div>
        </div>

        <ClipLoader
          v-if="!productsData?.items?.length && (pendingProductsData || pendingFiltersData)"
          placeholder="Подождите, идет загрузка..."
        />

        <template v-else-if="productsData?.items?.length">
          <PaginationList
            v-if="isCards || isTableMobileListing || !isMounted"
            v-slot="{ item }"
            class="list"
            :data="products"
            :data-count="productsData?.count"
            :get-row-key="(item) => item?.id"
            :size="size"
            :page="page"
            :more-number="moreNumber"
            :show-more-btn-params="{ loader: showMoreDisabler }"
            :disable-pagination="loadDataDisabler.value"
            :loading="productsLoaderIsShown"
            :mobile-listing="isTableMobileListing"
            @page-changed="onPageChanged"
            @load-more="onLoadMore"
          >
            <NuxtLink :to="getCorrectProductLink(item)">
              <ProductCard
                :id="item?.id?.toString()"
                :vendor-code="item?.vendorCode"
                :name="item?.name"
                :price="item?.priceInfo?.min"
                :marketplace-quantity="item?.offerCount"
                :specifications="item?.specifications"
                :pictures="item?.images?.map((el) => el.url)"
                :mobile="isTableMobileListing"
                :mtr-code="item?.mtrCode"
                :tip-text="getProductTipText(item)"
                :is-show-price="isShowPrice"
                :visible-compare="!!mpCompareProductsService"
                :is-comparison="item?.isComparison"
                :disabled-compare="mpCompareProductsService?.loadingAddToCompare.value"
                @product:click="$emit('clickProduct', item)"
                @compare:add="onAddToCompare(item)"
                @compare:open="mpCompareProductsService?.toggleModal()"
              />
            </NuxtLink>
          </PaginationList>

          <AppTable
            v-else-if="!isTableMobileListing && !isCards"
            class="list"
            disable-internal-calc-visible-rows
            :columns="columns"
            :data="products"
            :size="size"
            :pagination="{
              page,
              pageSize: size,
              dataCount: productsData?.count || 0,
              size,
            }"
            :show-more-count="moreNumber"
            :show-more-btn-params="{ loader: showMoreDisabler }"
            :disable-pagination="loadDataDisabler.value"
            :loading="productsLoaderIsShown"
            :sort="tableSort"
            @on-page-changed="onPageChanged"
            @on-show-more="onLoadMore"
            @on-sort-changed="onSortChanged"
            @on-row-click="onProductClick($event?.row, $event?.event)"
          >
            <template #images="{ row }">
              <div class="list-item image">
                <BaseTooltip
                  hover
                  offset-distance="4"
                  :placement="EPopperPlacement.Right"
                >
                  <BaseImg
                    class="main-image"
                    :src="row?.images?.[0]?.url || '/images/empty-product-images.png'"
                    alt="Продукт"
                  />
                  <template #content>
                    <BaseImg
                      :src="row?.images?.[0]?.url || '/images/empty-product-images.png'"
                      alt="Продукт"
                    />
                  </template>
                </BaseTooltip>
              </div>
            </template>

            <template #name="{ row }">
              <NuxtLink
                :to="getCorrectProductLink(row)"
                class="list-item name"
                @click="(event) => !isErp && event.stopPropagation()"
              >
                <span
                  v-if="getProductTipText(row)"
                  class="list-item__tip"
                >
                  {{ getProductTipText(row) }}
                </span>
                <span class="title anywhere-wrap">{{ row?.name }}</span>
                <div class="list-item__meta">
                  <div
                    v-if="row?.vendorCode"
                    class="description anywhere-wrap"
                  >
                    арт. {{ row.vendorCode }}
                  </div>
                  <div
                    v-if="row?.mtrCode"
                    class="description anywhere-wrap"
                  >
                    Код: {{ row.mtrCode }}
                  </div>
                </div>
              </NuxtLink>
            </template>

            <template
              v-for="filter in filters"
              :key="filter?.id"
              #[filter.id]="{ row }"
            >
              <NuxtLink
                :to="getCorrectProductLink(row)"
                class="list-item specification"
                @click="(event) => !isErp && event.stopPropagation()"
              >
                {{ row?.specifications?.find((specification) => specification?.id === filter?.id)?.value || '-' }}
              </NuxtLink>
            </template>

            <template #price="{ row }">
              <NuxtLink
                :to="getCorrectProductLink(row)"
                class="list-item price"
                @click="(event) => !isErp && event.stopPropagation()"
              >
                <span class="title anywhere-wrap">от {{ StringsHelper.toPriceString(row?.priceInfo?.min) }} ₽</span>
                <span class="description anywhere-wrap">{{ t(ERuLocales.MarketplaceQuantity, row?.offerCount) }}</span>
              </NuxtLink>
            </template>

            <template #offer="{ row }">
              <span class="title">
                {{ t(ERuLocales.MarketplaceQuantity, row?.offerCount) }}
              </span>
              от {{ row?.offerCount === 1 ? 'поставщика' : 'поставщиков' }}
            </template>

            <template #action="{ row }">
              <div class="list-item__actions">
                <CompareButton
                  v-if="mpCompareProductsService"
                  rounded
                  class="list-item__actions__compare"
                  :active="row.isComparison"
                  :disabled="mpCompareProductsService.loadingAddToCompare.value"
                  @add="onAddToCompare(row)"
                  @open="mpCompareProductsService.toggleModal()"
                />
                <NuxtLink
                  :to="getCorrectProductLink(row)"
                  class="list-item__actions__link"
                  @click="(event) => !isErp && event?.stopPropagation()"
                >
                  <button class="btn btn-round btn-primary">
                    <SvgIcon :src="EIconPath.NavigationMainArrowSvg" />
                  </button>
                </NuxtLink>
              </div>
            </template>
          </AppTable>

          <slot name="footerText">
            <div class="footer-text">
              Цены на товары указаны без НДС
            </div>
          </slot>
        </template>

        <EmptyBanner
          v-else
          class="empty-banner"
          title-text="К сожалению, нет подходящих товаров"
          reason-text="Убедитесь, что в значениях фильтров не установлены взаимоисключающие значения, или попробуйте смягчить требования подбора."
        >
          <button
            v-if="isErp ? isSelectedFilters || searchText : isSelectedFilters"
            key="clear-button"
            type="button"
            class="btn btn-text btn-clear-filter"
            :disabled="loadDataDisabler.value || showMoreDisabler.value"
            @click="onClearAllFilters"
          >
            Очистить все фильтры
          </button>
        </EmptyBanner>
      </template>
    </div>

    <FiltersDrawer
      :visible="visibleFilters"
      :filters="filtersForDrawer"
      :submit-loader="loadDataDisabler"
      :get-filter-base-props="(filter) => supplierCatalogsFilter.getFilterBaseProps(filter?.id)"
      @cancel="visibleFilters = false"
      @close="visibleFilters = false"
      @submit="onSubmitDrawer"
    />
  </div>
</template>

<script lang="ts" setup>
import Breadcrumbs from 'shared/components/bars/Breadcrumbs.vue';
import DropdownFilter from 'shared/components/DropdownFilter.vue';
import { IFilter, IFilterItem, IFilterResponse, IFilterSort, IFilterSortOption } from 'shared/models/filters.model';
import SvgIcon from 'shared/components/SvgIcon.vue';
import ProductCard from 'shared/components/ProductCard.vue';
import { IProduct, IProductsResponse } from 'shared/models/product.model';
import FiltersDrawer from 'shared/components/filters/FiltersDrawer.vue';
import DropdownSort from 'shared/components/DropdownSort.vue';
import { FilterUtils } from 'shared/utils/filterHelper.util';
import { EProductView } from 'enums/productView.enum';
import {
  endHeaderColumnsProducts,
  endHeaderColumnsProductsWithPrice,
  mapProductsSortDirection,
  MAX_ATTRIBUTES_LISTING_TABLE,
  MAX_ATTRIBUTES_LISTING_TILE,
  MAX_FILTERS_LISTING_TABLE_COLUMN,
  startHeaderColumnsProducts,
} from 'constants/products.const';
import { IColumnHeader, ITableSort } from 'shared/models/table.model';
import { SORT_FILTER, SORT_FILTER_WITH_PRICE } from 'constants/sortFilter.const';
import { ESortProductDirections } from 'shared/enums/sortDirections.enum';
import EmptyBanner from 'shared/components/EmptyBanner.vue';
import { EPopperPlacement } from 'shared/enums/popperPlacement.enum';
import { IBreadcrumbsItem } from 'shared/models/breadcrumbs.model';
import { VIEW_DATA_TYPE_LOCAL_STORAGE_KEY } from 'constants/components/productView.const';
import { getProductsTagsByPurpose } from 'shared/utils/productTags.util';
import { EProductTags } from 'shared/enums/productTags.enum';
import WindowWidthManager from 'shared/services/windowWidth.service';
import { AuthHelper } from 'utils/authHelper.util';
import BaseImg from 'shared/components/BaseImg.vue';
import SearchService from 'shared/services/search.service';
import { ref } from 'vue';
import QueryParamsService from 'shared/services/queryParams.service';
import { paginationSkip } from 'shared/utils/paginationSkip.util';
import { sortFormat } from 'shared/utils/sortFormat.util';
import { useCookie } from '#imports';
import Disabler from 'shared/utils/disablerHelper.util';
import { NavigatorHelper } from 'shared/utils/navigatorHelper.utils';
import { EIconPath } from 'shared/enums/iconPath.enum';
import CompareButton from 'shared/components/CompareButton.vue';
import { MpCompareProductsService } from 'services/compare-products/mpCompareProducts.service';
import { useDeleteFromCompareProducts } from 'composables/useDeleteFromCompareProducts';
import { ESortState } from 'shared/enums/sortState.enum';
import { useUserStore } from 'store/user.store';
import { debounce, cloneDeep } from 'lodash-es';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';
import { useBasis } from 'composables/useBasis';
import { EFetchKey } from 'enums/fetchKey.enum';
import { EProductFilterId } from 'enums/productFilterId.enum';
import { useSupplierCatalogsFilter } from 'composables/useSupplierCatalogsFilter';

const { ERuLocales } = await import('shared/enums/ruLocales.enum');

const props = withDefaults(
  defineProps<{
    categoryId?: string | number;
    title?: string;
    dataCount?: string;
    searchText?: string;
    breadcrumbs?: Array<IBreadcrumbsItem>;
    hideControls?: boolean;
    hideFilters?: boolean;
    initialViewData?: EProductView;
    disableChangeView?: boolean;
    hideProductsCount?: boolean;
    disableGoToProduct?: boolean;
    fiasId?: string;
    isErp?: boolean;
    categoryIds?: Array<string>;
    okeiCodeFilter?: string;
    isShowSubHeader?: boolean;
    items?: Array<IProduct>;
    disabledFilters?: boolean;
    lazy?: boolean;
    ociMode?: boolean;
    clientId?: number;
    isShowPrice?: boolean;
    mtrCode?: string;
    backButtonSlug?: string;
    defaultSort?: ESortProductDirections;
    basisId?: number;
    productsFetchKey?: string;
    filtersFetchKey?: string;
    related?: boolean;
    savedFilters?: Array<IFilterItem>;
    loadingProducts?: boolean;
  }>(),
  {
    isShowPrice: true,
    defaultSort: ESortProductDirections.Default,
    related: undefined,
    items: undefined,
  },
);

const emits = defineEmits<{
  (e: 'update-products-data', productsDataCount: number): void;
  (e: 'clickProduct', product: IProduct): void;
  (e: 'sortChanged', sort: ESortProductDirections): void;
  (e: 'searchClear'): void;
  (e: 'filtersChanged', filterItems: Array<IFilterItem>): void;
}>();

const searchService = inject<SearchService>(SearchService.getServiceName());
const queryParamsService = inject<QueryParamsService>(QueryParamsService.getServiceName());
const mpCompareProductsService = inject<MpCompareProductsService>(MpCompareProductsService.getServiceName());

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const sentry = useSentry();
const userStore = useUserStore();
const { unAuthRegionFiasId, authBasisId } = useBasis({
  basisId: props.basisId,
  fiasId: props.fiasId,
});
const viewData = useCookie(VIEW_DATA_TYPE_LOCAL_STORAGE_KEY, {
  default: () => props.initialViewData || EProductView.Card,
});

const { isMobile, isTablet, isSmallMobile } = WindowWidthManager.getAdaptivaRefs();

const watchSubscription = new WatchSubscription();

const visibleFilters = ref(false);
const page = ref(1);
const size = ref(20);
const skip = ref(0);
const sort = ref<string>(props.defaultSort);
const moreNumber = ref(20);
const filtersItems = ref<Array<IFilterItem>>(props.savedFilters?.length ? props.savedFilters : []);
const filtersOpeningMap = ref<Map<string, boolean>>(new Map());
const filtersData = ref<IFilterResponse>();
const isSetQueryParams = ref(false);
const isMounted = ref(false);
const isFilterSetQueryParams = ref(false);
const replacedText = ref<string | undefined>();
const contentRef = ref<HTMLDivElement | null>(null);

const showMoreDisabler = Disabler.getReactiveInstance();
const loadDataDisabler = Disabler.getReactiveInstance();

const supplierCatalogsFilter = useSupplierCatalogsFilter({
  categoryId: Number(props.categoryId),
  clientId: Number(userStore.userInfo?.clientId),
  basisId: authBasisId.value,
  fiasId: unAuthRegionFiasId.value,
  okeiCode: props.okeiCodeFilter,
  filterItems: filtersItems,
  ociMode: props.ociMode,
});

const isSelectedFilters = computed(() => !!FilterUtils.getSelectedFilters(filtersItems.value)?.length);

const sortOptions = computed<IFilterSort<ESortProductDirections>>(() => (props.isShowPrice ? SORT_FILTER_WITH_PRICE : SORT_FILTER));

const selectedSort = computed<IFilterSortOption<ESortProductDirections>>(
  () => sortOptions.value?.options.find((option) => option.sortDirection === sort.value) || sortOptions.value?.options[0],
);

const srcIconButton = computed(() => (viewData.value === EProductView.Card ? 'action/gallery-view' : 'action/listing-view'));

const isMobileOrTablet = computed(() => isTablet.value || isMobile.value || isSmallMobile.value);

const isMobileOrSmallMobile = computed(() => isMobile.value || isSmallMobile.value);

const isTableMobileListing = computed(() => viewData.value === EProductView.Table && isMobileOrTablet.value);

const isCards = computed(() => viewData.value === EProductView.Card || isTableMobileListing.value);

const filtersForListingHeader = computed(() =>
  filtersItems.value?.filter((filterItem) => filterItem.filter.purpose?.includes(EProductTags.FilterShort)),
);

const filtersForDrawer = computed(() =>
  filtersItems.value?.filter((filterItem) => filterItem.filter?.purpose?.includes(EProductTags.Filter)),
);

const products = computed(
  () =>
    productsData.value?.items?.map((item) => ({
      ...item,
      specifications: getProductsTagsByPurpose(
        item?.specifications || [],
        isTableMobileListing.value || viewData.value === EProductView.Table ? EProductTags.ListingTable : EProductTags.ListingTile,
        isTableMobileListing.value || viewData.value === EProductView.Table ? MAX_ATTRIBUTES_LISTING_TABLE : MAX_ATTRIBUTES_LISTING_TILE,
      ),
    })) ?? [],
);

const filters = computed(() => filtersData.value?.items || []);

const columns = computed<Array<IColumnHeader>>(() => [
  ...startHeaderColumnsProducts,
  ...filters.value
    .filter((filter) => filter.purpose?.includes(EProductTags.ListingTable))
    .slice(0, MAX_FILTERS_LISTING_TABLE_COLUMN)
    .map<IColumnHeader>((filter) => ({
      title: filter.name,
      field: filter?.id,
      cssClass: 'td-filter',
    })),
  ...(props.isShowPrice ? endHeaderColumnsProductsWithPrice : endHeaderColumnsProducts),
]);

const suggestText = computed(() => props.searchText && productsData.value?.suggestText);

const tableSort = computed<ITableSort | undefined>(() => getTableSort());

const {
  data: filtersResponse,
  refresh: refreshFiltersData,
  pending: pendingFiltersData,
} = await useLazyAsyncData<IFilterResponse | undefined>(
  props.filtersFetchKey || EFetchKey.Filters,
  async (nuxtApp) => await fetchFiltersData(nuxtApp?.ssrContext?.event.node.req.headers as Record<string, string> | undefined),
);

const {
  data: productsData,
  refresh: refreshProductsData,
  pending: pendingProductsData,
} = await useLazyAsyncData<IProductsResponse>(
  props.productsFetchKey || EFetchKey.Products,
  async (nuxtApp) => await fetchProductsData(nuxtApp?.ssrContext?.event.node.req.headers as Record<string, string> | undefined),
);

const loaderIsShown = computed(
  () => (pendingFiltersData.value || pendingProductsData.value) && (!productsData.value?.count || !filtersResponse.value?.count),
);

const productsLoaderIsShown = computed(
  () => loadDataDisabler.value || pendingProductsData.value || pendingFiltersData.value || showMoreDisabler.value || props.loadingProducts,
);

function onProductClick(product: IProduct, event?: MouseEvent): void {
  emits('clickProduct', product);
  if (props.disableGoToProduct || loadDataDisabler.value) {
    return;
  }

  const path = getCorrectProductLink(product);
  path && NavigatorHelper.goToUrl(router.resolve({ path }).href, event?.ctrlKey || event?.metaKey);
}

async function onClearAllFilters(): Promise<void> {
  emits('searchClear');
  FilterUtils.clearAllFilters(filtersItems);
  await refreshAllData();
  queryParamsService?.clear();
  emitFiltersChaged();
}

function setFiltersItems(newFiltersItems: Array<IFilterItem>): void {
  filtersItems.value = newFiltersItems || [];
}

function getFilterActiveText(filterState: IFilter, originalFilter: IFilter): string {
  return FilterUtils.getFilterActiveText(filterState, originalFilter, true, true);
}

async function onSubmitDrawer(newFiltersItems: Array<IFilterItem>): Promise<void> {
  setFiltersItems(newFiltersItems);
  await refreshAllData();
  visibleFilters.value = false;
  emitFiltersChaged();
}

function onChangeViewData(): void {
  if (props.disableChangeView) {
    return;
  }

  viewData.value = EProductView.Card === viewData.value ? EProductView.Table : EProductView.Card;
}

async function onPageChanged(newPage: number, newSkip: number): Promise<void> {
  page.value = newPage;
  skip.value = newSkip;
  contentRef.value?.scrollTo(0, 0);

  loadDataDisabler.activate();
  try {
    await refreshProductsData();
  } finally {
    loadDataDisabler.deactivate();
  }
}

async function fetchProductsData(ssrHeaders?: Record<string, string>): Promise<IProductsResponse> {
  if (!props.isErp) {
    setQueryParams();
  }
  await nextTick();
  if (Array.isArray(props.items)) {
    return Promise.resolve({
      items: props.items.slice(skip.value, skip.value + size.value),
      count: props.items.length,
    });
  } else {
    return AuthHelper.fetch('/api/v1/products', {
      params: {
        categoryId: props.categoryId,
        filter: getFiltersStringForQuery(),
        sort: sort.value,
        pageSize: size.value,
        skip: skip.value,
        text: route?.query.search ?? props.searchText,
        fiasId: unAuthRegionFiasId.value,
        pimCategoryId: props.categoryIds,
        okeiCode: props.okeiCodeFilter,
        ociMode: props.ociMode,
        basisId: authBasisId.value,
        ...(props.ociMode
          ? getOciModeParamsForFetch()
          : {
              clientId: userStore.userInfo?.clientId,
            }),
      },
      headers: ssrHeaders,
    });
  }
}

function getOciModeParamsForFetch(): Record<string, unknown> {
  return {
    useMtrPrice: true,
    useAllowedCategory: true,
    clientId: props.clientId,
    mtrCode: props.mtrCode,
  };
}

async function fetchFiltersData(ssrHeaders?: Record<string, string>): Promise<IFilterResponse | undefined> {
  if (props.disabledFilters) {
    filtersData.value = { items: [], count: 0 };
    return Promise.resolve({ items: [], count: 0 });
  }

  const tempQueryFilter: string = route?.query?.filter as string;

  const params = {
    filter: getFiltersStringForQuery(),
    categoryId: props.categoryId,
    text: props.searchText,
    fiasId: unAuthRegionFiasId.value,
    pimCategoryId: props.categoryIds,
    okeiCode: props.okeiCodeFilter,
    ociMode: props.ociMode,
    basisId: authBasisId.value,
    related: props.related,
    ...(props.ociMode ? getOciModeParamsForFetch() : {}),
  };
  const response = await AuthHelper.fetch<IFilterResponse | undefined>('/api/v1/filters', {
    params,
    headers: ssrHeaders,
  });
  const { currentFiltersItems, filters } = FilterUtils.getCombinedFilters(
    (filtersItems.value ?? []) as Array<IFilterItem>,
    response?.items,
    filtersData.value,
  );
  setFiltersItems(currentFiltersItems);
  setFilterQueryParams(tempQueryFilter);

  filtersData.value = filters.length ? { items: filters, count: filters?.length } : response;
  return response;
}

function getFiltersStringForQuery(): string | undefined {
  const query = route?.query?.filter as string | undefined;

  return isSelectedFilters.value || isMounted.value
    ? FilterUtils.makeQueryObjectString(filtersItems?.value)
    : query && typeof query !== 'string'
    ? JSON.stringify(query)
    : query;
}

async function onLoadMore(newSkip: number): Promise<void> {
  showMoreDisabler.activate();
  try {
    skip.value = newSkip;
    const data = await fetchProductsData();
    if (data) {
      productsData.value?.items?.push(...(data?.items || []));
      emits('update-products-data', productsData.value?.count);
      page.value++;
    }
  } catch (error) {
    sentry?.captureException?.(error);
    console.error(error);
  } finally {
    showMoreDisabler.deactivate();
  }
}

function onUpdateSort(option: IFilterSortOption): void {
  sort.value = option.sortDirection;
  skip.value = 0;
  page.value = 1;
}

function onSortChanged(newSort: ITableSort | null): void {
  sort.value = !newSort
    ? ESortProductDirections.Default
    : mapProductsSortDirection.get(`${newSort.field}-${newSort.order}`) || ESortProductDirections.Default;
  skip.value = 0;
  page.value = 1;
}

async function onUpdateFilters(filterItem: IFilterItem, value: IFilter): Promise<void> {
  filterItem.filterState = value;
  await updateFilters();
  emitFiltersChaged();
}

async function onResetFilters(filterId: string): Promise<void> {
  await nextTick();
  await updateFilters();
  filtersOpeningMap.value?.set(filterId, false);
  emitFiltersChaged();
}

async function onSubmitFilters(filterId: string): Promise<void> {
  await nextTick();
  await updateFilters();
  filtersOpeningMap.value?.set(filterId, false);
  emitFiltersChaged();
}

function emitFiltersChaged(): void {
  emits('filtersChanged', cloneDeep(filtersItems.value));
}

function clearFiltersOpeningMap(): void {
  filtersOpeningMap.value?.clear();
}

async function updateFilters(): Promise<void> {
  page.value = 1;
  skip.value = 0;
  await refreshAllData();
}

async function refreshAllData(): Promise<void> {
  loadDataDisabler.activate();
  try {
    await refreshFiltersData();
    await refreshProductsData();
  } finally {
    loadDataDisabler.deactivate();
  }
}

function initQueryParams(): void {
  const queryParams = queryParamsService?.getQueryParams();
  searchService?.setParams(queryParams?.search, queryParams?.categoryId);
  sort.value = sortFormat(queryParams?.sort as ITableSort) || sort.value;
  page.value = Number(queryParams?.page) || page.value;
  skip.value = paginationSkip(Number(queryParams?.page), size.value) || skip.value;
}

function setFilterQueryParams(tempQueryFilter: string): void {
  if (tempQueryFilter && !isFilterSetQueryParams.value) {
    setFiltersItems(queryParamsService?.getFilterFromQueryParams(filtersItems.value as IFilterItem[], tempQueryFilter) ?? []);
  }
  isFilterSetQueryParams.value = true;
}

function setQueryParams(): void {
  const queryParams = queryParamsService?.getQueryParams();
  if (!isSetQueryParams.value && queryParams) {
    sort.value = sortFormat(queryParams?.sort as ITableSort) || sort.value;
    page.value = Number(queryParams?.page) || page.value;
    skip.value = paginationSkip(Number(queryParams?.page), size.value) || skip.value;
    isSetQueryParams.value = true;
  }
}

async function saveQueryParams(): Promise<void> {
  if (isSetQueryParams.value && isMounted.value && isFilterSetQueryParams.value) {
    await queryParamsService?.saveQueryParams({
      search: suggestText.value ?? props.searchText,
      page: skip.value / size.value + 1,
      sort: sort.value !== ESortProductDirections.Default ? sort.value : undefined,
      filter: isSelectedFilters.value ? (filtersItems.value as IFilterItem[]) : [],
      categoryId: props.categoryId,
    });

    if (!isSelectedFilters.value) {
      navigateTo({
        query: {
          ...route.query,
          filter: undefined,
        },
      });
    }
  }
}

function initFilters() {
  if (!filtersData.value && !!filtersResponse.value) {
    const { currentFiltersItems, filters } = FilterUtils.getCombinedFilters(
      (filtersItems.value ?? []) as Array<IFilterItem>,
      filtersResponse.value?.items,
      filtersData.value,
    );
    setFiltersItems(currentFiltersItems);

    const tempQueryFilter: string = route?.query?.filter as string;
    setFilterQueryParams(tempQueryFilter);

    filtersData.value = filters.length ? { items: filters, count: filters?.length } : filtersResponse.value;
  }
}

function getCorrectProductLink(product: IProduct): string | undefined {
  if (props.isErp) {
    return undefined;
  }

  if (props.ociMode) {
    return `/oci${product.slug}`;
  }

  const availableFilterValue = FilterUtils.getFilterValueById(filtersItems.value, EProductFilterId.Available);
  const directDeliveryFilterValue = FilterUtils.getFilterValueById(filtersItems.value, EProductFilterId.DirectDelivery);
  const searchParams = new URLSearchParams();

  if (availableFilterValue) {
    searchParams.append(EProductFilterId.Available, availableFilterValue);
  }

  if (directDeliveryFilterValue) {
    searchParams.append(EProductFilterId.DirectDelivery, directDeliveryFilterValue);
  }

  const searchParamsString = searchParams.toString();
  return `${product.slug}${searchParamsString.trim() ? `?${searchParamsString.trim()}` : ''}`;
}

function getProductTipText(product: IProduct): string | undefined {
  return product?.mtrCode && props?.searchText && product.mtrCode === props.searchText ? 'Найдено по коду материала' : undefined;
}

async function resetData(): Promise<void> {
  filtersData.value = undefined;
  FilterUtils.clearAllFilters(filtersItems);
  skip.value = 0;
  page.value = 1;
  await refreshAllData();
}

async function setSuggestText(isInit = false): Promise<void> {
  if (suggestText.value) {
    if (suggestText.value !== props.searchText) {
      replacedText.value = props.searchText;
    }

    searchService?.setParams(suggestText.value, undefined);
    if (isInit) {
      await queryParamsService?.saveQueryParamItem('search', suggestText.value);
    }
  }
}

async function onAddToCompare(product: IProduct): Promise<void> {
  if (!product.categories.length) {
    return;
  }

  const productCategory = product.categories[0];
  const isComparison = !!(await mpCompareProductsService?.addToCompare(
    MpCompareProductsService.createProductToCompare({
      ...product,
      categoryId: productCategory?.id,
      categoryName: productCategory.name,
    }),
  ));

  const productData = productsData.value?.items.find((item) => Number(item?.id) === Number(product?.id));
  if (productData) {
    productData.isComparison = isComparison;
  }
}

useDeleteFromCompareProducts((modelIds) => {
  if (!modelIds?.length) {
    return;
  }

  modelIds.forEach((modelId) => {
    const product = productsData.value?.items.find((product) => Number(product?.id) === modelId);

    if (product) {
      product.isComparison = false;
    }
  });
});

function getTableSort(): ITableSort | undefined {
  const tableSortKey = [...mapProductsSortDirection].find(([, value]) => value === sort.value)?.[0];

  if (!tableSortKey) {
    return;
  }

  return {
    field: tableSortKey.split('-')?.[0],
    order: tableSortKey.split('-')?.[1] as ESortState,
  };
}

function onChangeDisplayFilter(filterId: string, displayed: boolean): void {
  const filterOpeningState = filtersOpeningMap.value.get(filterId);

  if (filterOpeningState === displayed) {
    return;
  }

  filtersOpeningMap.value?.set(filterId, displayed);
}

const delayedHandler = debounce(async (newSearchText) => {
  if (!newSearchText) {
    await queryParamsService?.clearQueryParam('search');
    await nextTick();
    route.query.search = undefined;
  }

  await resetData();
  setSuggestText();
}, 200);

watchSubscription.add(
  watch(sort, async (newSort) => {
    emits('sortChanged', newSort as ESortProductDirections);
    if (!isMounted.value) {
      return;
    }

    await refreshProductsData();
  }),

  watch(
    () => [props.searchText, props.categoryId, props.categoryIds],
    ([newSearchText, newCategoryId, newCategoryIds], oldValues) => {
      const [oldSearchText, oldCategoryId, oldCategoryIds] = oldValues || [];

      if (
        newSearchText !== oldSearchText ||
        newCategoryId !== oldCategoryId ||
        JSON.stringify(newCategoryIds) !== JSON.stringify(oldCategoryIds)
      ) {
        delayedHandler(newSearchText);
      }
    },
    {
      deep: true,
      flush: 'post',
    },
  ),

  watch(productsData, async () => await saveQueryParams(), { flush: 'post' }),

  watch(productsData, (newProductsData) => emits('update-products-data', newProductsData?.count || 0)),

  watch(
    () => props.initialViewData,
    (newInitialViewData) => {
      if (newInitialViewData) {
        viewData.value = newInitialViewData || viewData.value || EProductView.Card;
      }
    },
    { immediate: true },
  ),

  watch(
    () => props.isShowPrice,
    async () => await refreshAllData(),
  ),

  watch(
    () => loaderIsShown.value,
    () => clearFiltersOpeningMap(),
  ),

  watch(
    () => props.items,
    async (newItems) => Array.isArray(newItems) && (await refreshProductsData()),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(() => {
  initFilters();
  initQueryParams();
  isMounted.value = true;
  setSuggestText(true);
  emits('update-products-data', productsData.value?.count || 0);
});
</script>

<style lang="scss">
@import 'styles/base/common/variables';

$color-black: #031912;
$color-gray: #657a73;
$color-gray-100: #ececec;

.product-view {
  max-width: 1600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 700px;

  &.erp {
    &:has(.filters) {
      .product-view__wrapper {
        .product-view__header {
          padding-top: 35px !important;
        }
      }
    }
  }

  &:not(:has(.buttons--tab-title)) {
    .product-view__header {
      .headline {
        h1 {
          font-size: 32px;
        }
      }
    }
  }

  &__header {
    .mm-back-button {
      padding: 8px 16px 8px 10px;
      margin-bottom: 20px;
      width: max-content;

      &__icon {
        width: 20px;
        margin: 0px 8px 0px 0px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .btn-clear-filter {
    font-weight: 500;
  }

  &__wrapper {
    width: 100%;

    .headline {
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      h1 {
        font-style: normal;
        color: $color-black;
        margin-bottom: 0;
        font-size: 20px;
        line-height: 28px;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 2px;
      }
    }

    .td-image {
      width: 100px;
    }

    .td-name {
      min-width: 300px;
    }

    .mm-table__header-row-content--sortable {
      z-index: 2;
    }

    .controls {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 40px;

      &:not(:has(.filters)) {
        padding-bottom: 32px;
        padding-top: 32px !important;

        .buttons {
          border-bottom: none;
          flex-direction: row !important;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          &--tab-title {
            min-height: 36px;
            display: flex;
            align-items: center;

            p {
              font-size: 20px;
              line-height: 28px;
              font-weight: 500;
              margin-bottom: 0;
            }
          }
        }
      }

      .filters {
        margin-right: 60px;

        &__wrap {
          display: flex;
          align-items: center;
          flex-flow: row wrap;
          gap: 4px;
        }

        &__item {
          &:last-child {
            margin-right: 0;
          }

          .mm-range {
            margin-bottom: -24px;

            .mm-input__label {
              transform: translateY(-17px);
            }

            .mm-input {
              padding-bottom: 8px;
            }
          }

          .popper {
            z-index: 29;
          }

          .popper[data-popper-placement='top'] {
            z-index: 60;
          }
        }

        .btn-secondary {
          padding: 9px 16px;
        }
      }

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;

        .btn-primary {
          margin-right: 7px;
        }

        .btn-secondary {
          padding: 0;
        }
      }

      .btn {
        padding: 8px 16px;
      }
    }

    .list {
      box-shadow: none;
      overflow: inherit;
      margin: 40px 8px 0;

      .list__container {
        max-width: 1584px;
      }

      &-item {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $color-black;

        &.image {
          height: 52px;
          width: 52px;
          position: relative;

          .mm-tooltip {
            & > div {
              height: 52px;
              width: 52px;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          .mm-tooltip.mm-tooltip--base {
            .popper {
              background: $white;
              box-shadow: 0 2px 18px rgba(0 0 0 / 12%);
              border-radius: 8px;
              padding: 0;
              width: 262px;
              height: 262px;
              max-width: 262px;
            }
          }
        }

        &.name,
        &.price {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }

        & > .description {
          padding-top: 4px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $color-gray;
        }

        &.price {
          & > .title {
            font-weight: 500;
          }

          & > .description {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }

        &.actions {
        }

        &__tip {
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          color: $text-white;
          background-color: $link;
          padding: 3px 8px;
          border-radius: 4px;
          width: max-content;
          margin-bottom: 4px;
        }

        &__actions {
          display: flex;
          flex-direction: row;
          margin-top: 8px;
          gap: 8px;

          &__link {
            button {
              margin-right: 8px;
              height: 36px;
              width: 36px;
              padding: 6px;
              display: flex;
              justify-content: center;

              &:last-child {
                margin-right: 0;
                margin-left: auto;
              }
            }
          }
        }

        &__meta {
          display: flex;
          flex-direction: row;
          margin-top: 4px;

          .description {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: $light-green;

            &:not(&:last-child) {
              padding-right: 8px;
              margin-right: 8px;
              border-right: 1px solid $dark-gray;
            }
          }
        }
      }

      .mm-table__table {
        border-radius: 8px;
        box-shadow: 0 0 0 1px #e6e6e6;
        overflow: auto;
        width: 100%;

        &.mm-table--theme-default .mm-table__body > tr {
          &:not(:first-child) {
            td {
              border-top: 1px solid $gray-200 !important;
              border-bottom: 1px solid $gray-200 !important;
            }
          }

          &:last-child {
            td {
              border-bottom-width: 0 !important;
            }
          }
        }

        &.mm-table--theme-default:not(.mm-table--children)
          .mm-table__body
          > tr:not(.mm-table__row--show-more):not(.mm-table__row--empty):hover {
          td {
            border-color: $gray-200 !important;
          }
        }

        thead {
          th {
            &:first-child {
              border-top-left-radius: 8px;

              .mm-table__header-row-content--sortable {
                padding-left: 12px;
              }
            }

            &:last-child {
              border-top-right-radius: 8px;
            }
          }
        }

        tbody {
          tr {
            td {
              padding-left: 5px;

              &:first-child {
                padding-left: 32px;
              }

              &:nth-child(2) {
                padding-left: 3px;
              }

              &:last-child {
                padding-right: 32px;
              }
            }
          }
        }
      }
    }

    .brands {
      margin-top: 40px;

      h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 28px;
      }
    }

    .popular {
      margin-top: 40px;
    }

    .footer-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $text-dark-green;
      margin-top: 16px;
      text-align: center;
      margin-bottom: 16px;
    }

    .suggest-text {
      color: $color-gray;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }

  .empty-banner {
    margin: 40px 40px 60px;
    max-width: 1520px;

    .btn {
      font-weight: 500;
      max-width: 152px;
    }
  }

  .controls-sort {
    position: relative;

    .popper {
      .sort__popup .mm-sort-filter .mm-sort-filter__list .ps .ps__rail-x {
        display: none !important;
      }

      .mm-sort-filter {
        width: 264px;
      }

      .sort__popup {
        width: 264px;

        .mm-checkmark-simple {
          margin-right: -16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .product-view__header {
    padding: 0 40px;
  }

  .headline {
    h1 {
      font-weight: 400;
      font-size: 32px;
      line-height: 36px;
      margin-right: 16px;
      margin-bottom: 0;
    }

    span {
      color: $color-gray;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .controls {
    padding: 0 40px;

    .filters {
      margin-right: 60px;
    }
  }

  .td-filter {
    width: 220px;
  }

  .product-view.erp {
    .product-view__wrapper {
      .controls {
        &:not(:has(.filters)) {
          padding-top: 32px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .headline {
    h1 {
      font-weight: 500;
      font-size: 32px;
      line-height: 36px;
      margin-right: 16px;
    }

    span {
      color: $color-gray;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .controls {
    padding: 0 32px;
    margin-top: 28px !important;

    .filters {
      margin-right: 197px;
    }
  }

  .product-view.erp {
    .controls {
      margin-top: 0 !important;
    }
  }

  .product-view__header {
    padding: 0 32px;
  }

  .mm-table__table {
    tbody {
      tr {
        td {
          &:first-child {
            padding-left: 32px;
          }

          &:last-child {
            padding-right: 32px;
          }
        }
      }
    }
  }

  .td-filter {
    width: 150px;
  }

  .product-view.erp {
    .product-view__wrapper {
      &:has(.filters) {
        .product-view__header {
          padding-top: 40px !important;
          margin-bottom: -5px !important;
        }
      }

      .controls {
        &:not(:has(.filters)) {
          padding-top: 32px !important;
          margin-top: 0 !important;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }

    &.no-tabs {
      margin-top: -8px !important;

      .product-view__wrapper {
        .controls {
          padding-top: 20px !important;
          margin-top: 0 !important;
          padding-bottom: 32px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .headline {
    h1 {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      margin-right: 8px;
    }

    span {
      font-size: 12px;
      color: $color-gray;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .product-view__header {
    padding: 0 20px;

    .mm-back-button {
      margin-top: -19px;
    }

    .suggest-text {
      font-size: 12px;
      line-height: 16px;
    }

    .headline {
      h1 {
        font-weight: 500;
        font-size: 24px !important;
        line-height: 32px !important;
      }
    }
  }

  .list {
    padding: 0 4px;
  }

  .controls {
    padding: 0 20px;
    flex-direction: column-reverse !important;

    &:not(:has(.filters)) {
      padding-top: 32px !important;

      .buttons {
        padding-top: 0 !important;
        margin-bottom: 0;
        padding-bottom: 0;

        &--tab-title {
          p {
            font-size: 16px !important;
            line-height: 24px !important;
          }
        }
      }
    }

    .buttons {
      flex: 1;
      width: 100%;
      padding-bottom: 12px;
      border-bottom: 1px solid $color-gray-100;
      margin-bottom: 11px;
      justify-content: space-between !important;
      flex-direction: row-reverse !important;
    }

    .filters {
      margin-right: 0 !important;
    }
  }

  .product-view {
    &:not(:has(.buttons--tab-title)) {
      .product-view__header {
        padding-top: 32px !important;
        margin-bottom: -8px !important;
      }
    }

    &.erp {
      .product-view__wrapper {
        .controls {
          .btn.btn-square.btn-square--36px.btn-secondary {
            width: 28px;
            height: 28px;

            svg {
              width: 16px;
              height: 16px;
            }
          }

          .btn.btn-primary {
            font-size: 12px;
            line-height: 16px;
            width: 112px;
            height: 28px;
            padding: 5px 8px 7px 8px;

            svg {
              width: 12px;
              height: 12px;
            }
          }
        }

        &:has(.filters) {
          .product-view__header {
            padding-top: 29px !important;
            margin-left: -20px;
            margin-right: -20px;
            margin-top: -15px !important;

            .headline {
              margin-top: 24px;

              h1 {
                font-size: 16px !important;
                line-height: 24px !important;
              }
            }
          }

          .controls {
            margin-left: -20px;
            margin-right: -20px;
          }
        }

        .controls {
          padding-top: 14px !important;
          margin-top: 0 !important;

          &:not(:has(.filters)) {
            padding-top: 32px !important;
            margin-top: 0 !important;
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }

      &.no-tabs {
        .product-view__wrapper {
          .controls {
            margin-top: 0 !important;
            padding-top: 14px !important;
            padding-bottom: 32px !important;
          }

          .product-view__header {
            .headline {
              h1 {
                font-size: 16px !important;
                line-height: 24px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumbs__wrap {
    overflow: hidden;
    height: 24px;
    margin-right: -20px;

    .mm-breadcrumbs {
      flex-wrap: nowrap;
      overflow: auto;
      padding-bottom: 10px;
    }
  }

  .headline {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;

    h1 {
      font-weight: 500;
      font-size: 16px !important;
      line-height: 24px !important;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding-top: 4px;
      color: $color-gray;
    }
  }

  .product-view__header {
    padding: 0 20px;
    margin-bottom: -12px !important;

    .mm-back-button {
      margin-top: 13px;
    }

    .suggest-text {
      font-size: 12px;
      line-height: 16px;
      padding-top: 17px;
    }
  }

  .product-view .product-view__wrapper .list {
    margin: 40px 0 0;
    padding: 0 4px;
  }

  .controls {
    padding: 0 20px;
    margin-top: 32px !important;
    flex-direction: column-reverse !important;

    &:not(:has(.filters)) {
      padding-top: 32px !important;
      padding-bottom: 0 !important;

      .buttons {
        align-items: flex-end !important;
        padding-top: 0 !important;
        padding-bottom: 0;
        margin-bottom: 0;

        .btn.btn-square.btn-square--36px.btn-secondary {
          min-width: 36px;
        }

        &--tab-title {
          p {
            font-size: 16px !important;
            line-height: 24px !important;
          }
        }
      }
    }

    .buttons {
      flex: 1;
      width: 100%;
      padding-bottom: 12px;
      border-bottom: 1px solid $color-gray-100;
      margin-bottom: 11px;
      justify-content: space-between !important;
      flex-direction: row-reverse !important;

      .btn-primary {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .btn-secondary {
        margin-right: 8px;
      }
    }

    .filters {
      width: 100%;
      margin-right: 0 !important;
      overflow: hidden;
      height: 40px;

      &__wrap {
        flex-wrap: nowrap !important;
        overflow-y: auto;
        width: 100%;
        padding-bottom: 15px;
      }
    }
  }

  .product-view.erp {
    .product-view__wrapper {
      &:has(.filters) {
        margin-top: 8px;

        .product-view__header {
          margin-left: -20px;
          margin-right: -20px;

          .headline {
            h1 {
              font-size: 16px !important;
              line-height: 24px !important;
            }
          }
        }
      }

      .controls {
        padding-top: 12px !important;
        margin-top: 12px !important;
        padding-bottom: 0 !important;
        margin-left: -20px !important;
        margin-right: -20px !important;

        .btn.btn-square.btn-square--36px.btn-secondary {
          width: 28px !important;
          height: 28px;
          min-width: unset;

          svg {
            width: 20px;
            height: 20px;
          }
        }

        .btn.btn-primary {
          height: 28px;
          font-size: 12px;

          svg {
            width: 12px;
          }
        }

        &:not(:has(.filters)) {
          padding-top: 32px !important;
          margin-top: 0 !important;
          padding-left: 40px !important;
          padding-right: 20px;
        }
      }
    }

    &.no-tabs {
      margin-top: 4px !important;

      .product-view__wrapper {
        .controls {
          padding-top: 24px !important;
          margin-top: 0 !important;
          padding-bottom: 0 !important;
        }

        .product-view__header {
          .headline {
            h1 {
              font-size: 16px !important;
              line-height: 24px !important;
            }
          }
        }
      }
    }
  }

  .product-view .controls-sort {
    position: unset;
  }
}

@media only screen and (max-width: 660px) {
  .list__item {
    &:has(.mm-product-card.mobile) {
      width: 100%;
      max-width: 100% !important;
      flex: 0 0 100% !important;
      border-bottom: none !important;
      border-left-width: 1px !important;

      &:first-child {
        border-bottom: none;
        border-top-right-radius: 8px !important;
      }

      &:not(:first-child):not(:last-child) {
        border-radius: 0;
        border-top: 1px solid $gray-200;
      }

      &:nth-child(2) {
        border-top-right-radius: 0 !important;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top: 1px solid $gray-200;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .product-view .product-view__wrapper .list {
    margin: 40px 0 0;
    padding: 0 4px;

    .mm-pagination {
      padding: 20px 0;
    }
  }
}
</style>
